import { Link, graphql } from "gatsby";
import React, { Component } from "react";

import Helmet from "react-helmet";
import Layout from "../layout";
// import PortfolioListing from "../components/PortfolioListing";
import PostListing from "../components/PostListing";
import SEO from "../components/SEO";
import SimpleListing from "../components/SimpleListing";
import config from "../../data/SiteConfig";
import reiyan from "../../content/images/rei.png";
import speaking from "../../data/speaking";

export default class Index extends Component {
  render() {
    const { data } = this.props;

    const latestPostEdges = data.latest.edges;
    // const latestPortfolioEdges = data.portfolio.edges;

    return (
      <Layout>
        <Helmet title={`${config.siteTitle} – Overthinker`} />
        <SEO />
        <div className="container">
          <div className="lead">
            <div className="elevator">
              <h2>{`Hi, i'm Rei`} </h2>
              <p>
                idk what happened, just use me as a bad example
                <br />
                <i>semua tulisan di sini dibuat dengan ♥️️</i>
              </p>
            </div>
            <img
              height="200px"
              width="200px"
              src={reiyan}
              className="newsletter-avatar"
              alt="Rei Yan"
            />
          </div>
        </div>

        <div className="container front-page">
          <section className="section">
            <h2>
              Latest Articles
              <Link to="/blog" className="view-all">
                View all
              </Link>
            </h2>
            <PostListing simple postEdges={latestPostEdges} />
          </section>
          {/* <section className="section">
            <h2>
              Latest Portfolio
              <Link to="/portfolio" className="view-all">
                View all
              </Link>
            </h2>
            <PortfolioListing simple postEdges={latestPortfolioEdges} />
          </section> */}
          <section className="section">
            <h2>Talks History</h2>
            <SimpleListing simple data={speaking} />
          </section>
        </div>
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query IndexQuery {
    latest: allMarkdownRemark(
      limit: 5
      sort: { fields: [fields___date], order: DESC }
      filter: { frontmatter: { template: { eq: "post" } } }
    ) {
      edges {
        node {
          fields {
            slug
            date
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            categories
            thumbnail {
              childImageSharp {
                fixed(width: 150, height: 150) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            date
            template
          }
        }
      }
    }
    portfolio: allMarkdownRemark(
      limit: 5
      sort: { fields: [fields___date], order: DESC }
      filter: { frontmatter: { template: { eq: "portfolio" } } }
    ) {
      edges {
        node {
          fields {
            slug
            date
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            categories
            thumbnail {
              childImageSharp {
                fixed(width: 150, height: 150) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            date
            template
          }
        }
      }
    }
  }
`;
